<template lang="pug">
.accuracy
	Menu
		template(#title)
			.flex.items-center
				| Image Accuracy
				Tag.mt-4.ml-8(:label="target" type="green")

	.content
		template(v-if="isMounted")
			.container
				.flex.mb-24.mt-24
					select(v-model="group")
						option(v-for="item in groupOptions" :value="item.value") Group By: {{ item.title }}
					.accuracy__filter.filter
						Tooltip(trigger="clickToToggle")
							template(slot="reference")
								.filter__label.flex.items-center
									| Result
									ChevronDownIcon.filter__label-icon(:size="16")
							.filter__list
								Checkbox.filter__item.mt-2(
									v-for="item in filterOptions"
									:key="item.label"
									:label="item.label"
									:value="item.value"
									type="gray"
									size="sm"
									@change="changeFilter(item)"
								)

				.accuracy__section
					template(v-if="isLoading")
						.mt-32
							PuSkeleton(height="20px" color="#E0E0E0")
						.mt-16
							PuSkeleton(height="20px" color="#E0E0E0")
						.mt-16
							PuSkeleton(height="20px" color="#E0E0E0")
						.mt-16
							PuSkeleton(height="20px" color="#E0E0E0")
					.accuracy__chart(v-else-if="response")
						BarChart(
							ref="bar"
							:chartData="stackedBarChartData"
							:options="stackedBarChartOptions"
							:style="{'height': `${180 + (40 * (lengthOfResult - 1)) }px`}"
						)
					template(v-else) Чтобы данные отобразились в дашборде, выберите значения в фильтрах и нажмите "Apply filter”
			.container
				ContainerForData.mt-24(width="100%")
					template(#header-left)
						span.mr-20 Products on page
						select(v-model="amountOnPage")
							option(v-for="item in productsOnPage" :value="item") {{ item }}

					template(#data)
						template(v-if="isLoading")
							.mt-32
								PuSkeleton(height="20px" color="#E0E0E0")
							.mt-16
								PuSkeleton(height="20px" color="#E0E0E0")
							.mt-16
								PuSkeleton(height="20px" color="#E0E0E0")
							.mt-16
								PuSkeleton(height="20px" color="#E0E0E0")
						template(v-else-if="response.productImageScoreDetailed")
							.flex.flex-wrap.cards
								template(v-for="(item, index) of response.productImageScoreDetailed")
									ProductCard.accuracy__product.mb-16(
										v-if="index < amountOnPage"
										:key="index"
										:product="item"
									)
						template(v-else)
							| Чтобы данные отобразились в дашборде, выберите значения в фильтрах и нажмите "Apply filter”

					template(
						v-if="hasPagination"
						#pagination
					)
						.element-container__content.flex.items-center.justify-center
							pagination(
								:total="response.productImageScoreDetailedTotalCount"
								:current="current"
								:limit="limit"
								@change-page="current = $event"
							)

		.empty-filter(v-else) Пожалуйста, выберите значения фильтра

</template>

<script>
import { renameYandexIA } from '@/helpers/RenameYandexPokupki'
import Menu from '@/components/Menu/Menu.vue'
import Tag from '@/components/Tag/Tag.vue'
import Tabs from '@/components/Nestle/Tabs'
import BarChart from '@/components/Chart/BarChart'
import TabsSection from '@/components/Tabs/Tabs.vue'
import TabItem from '@/components/Tabs/TabItem.vue'
import ContainerForData from '@/components/Nestle/ContainerForData'
import ProductCard from '@/components/Nestle/ProductCard/ProductCard'
import Tooltip from '@/components/Elements/Tooltip.vue'
import Checkbox from '@/components/Elements/Checkbox.vue'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'
import Pagination from "@/components/Pagination/Pagination.vue"

export default {
	components: {
		Menu,
		Tag,
		Tabs,
		BarChart,
		TabsSection,
		TabItem,
		ContainerForData,
		ProductCard,
		Tooltip,
		Checkbox,
		ChevronDownIcon,
		Pagination,
	},
	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},

		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
    address: {
        type: String,
        default: '',
    },
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			target: '80%',
			amountOnPage: 6,
			productsOnPage: [6, 12, 24, 64, 120],
			group: 1,
			groupOptions: [
				{
					value: 1,
					title: 'Online Store',
					slug: 'imageScoreCountGroupByOnlineStore',
					name: 'store',
				},
				{
					value: 2,
					title: 'Category',
					slug: 'imageScoreCountGroupByCategory',
					name: 'category',
				},
				{
					value: 3,
					title: 'Brand',
					slug: 'imageScoreCountGroupByBrand',
					name: 'brand',
				},
			],
			filter: 1,
			filterOptions: [
				{
					label: 'Pass',
					value: true,
					slug: 'pass',
				},
				{
					label: 'Partial',
					value: true,
					slug: 'partial',
				},
				{
					label: 'Incorrect',
					value: true,
					slug: 'incorrect',
				},
				{
					label: 'No Reference',
					value: true,
					slug: 'noReference',
				},
				{
					label: 'Unreadable',
					value: true,
					slug: 'unreadable',
				},
			],
			tab: 1,
			isLoading: true,
			products: null,
			imageAccuracy: null,
			imageAccuracyLabels: {
				pass: 'Pass',
				partial: 'Partial',
				incorrect: 'Incorrect',
				noReference: 'No Reference',
				unreadable: 'Unreadable',
			},
			current: 1,
			limit: 6,
			offset: 0,
			isMounted: false,
			response: null,
			selectedType: null,
		}
	},
	computed: {
		lengthOfResult() {
			if (!this.response[this.activeGroup]) return
			return this.response[this.activeGroup].length
		},
		hasPagination() {
			if (!this.response?.productImageScoreDetailedTotalCount) return
			return this.response.productImageScoreDetailedTotalCount / this.limit > 1
		},
		activeGroup() {
			return this.groupOptions.find((item) => item.value === this.group).slug
		},
		imageAccuracyByGroup() {
			if (!this.response) {
				return null
			}
			return this.response[this.activeGroup]
		},
		activeFilters() {
			return this.filterOptions.filter((item) => item.value && item.slug !== 'all')
		},
		filteredImageAccuracy() {
			let filters = this.activeFilters.map((item) => item.slug)
			return this.imageAccuracyByGroup.map((item) => {
				let el = {}
				Object.values(filters).forEach((key) => {
					return el[key] = item[key]
				})

				const nameOfField = this.groupOptions.find(item => item.slug === this.activeGroup).name
				return { ...el, name: item[nameOfField + 'Name']}
			})
		},
		stackedBarChartData() {
			return {
				labels: this.filteredImageAccuracy.map((item) => item.name),
				datasets: [
					{
						label: this.imageAccuracyLabels['pass'],
						borderColor: '#4CAF50',
						backgroundColor: '#4CAF50',
						data: this.filteredImageAccuracy.map((item) => item.pass),
						minBarLength: 2,
					},
					{
						label: this.imageAccuracyLabels['partial'],
						borderColor: '#FEB237',
						backgroundColor: '#FEB237',
						data: this.filteredImageAccuracy.map((item) => item.partial),
					},
					{
						label: this.imageAccuracyLabels['incorrect'],
						borderColor: ' #E53914',
						backgroundColor: '#E53914',
						data: this.filteredImageAccuracy.map((item) => item.incorrect),
					},
					{
						label: this.imageAccuracyLabels['noReference'],
						borderColor: '#FD5A31',
						backgroundColor: '#FD5A31',
						data: this.filteredImageAccuracy.map((item) => item.noReference),
					},
					{
						label:  this.imageAccuracyLabels['unreadable'],
						borderColor: '#FE6E49',
						backgroundColor: '#FE6E49',
						data: this.filteredImageAccuracy.map((item) => item.unreadable),
					},
				]
			}
		},
		stackedBarChartOptions() {
			return {
				indexAxis: 'y',
				responsive: true,
				scales: {
					x: {
						stacked: true,
					},
					y: {
						stacked: true,
					}
				},
				plugins: {
					datalabels: {
						display: function (context){
							return context.dataset.data[context.dataIndex] > 0
						},
						color: 'white',
						backgroundColor: 'transparent',
						borderColor: 'transparent',
						font: {
							weight: 'bold',
							size: 14
						}
					},
					responsive: true,
					tooltip: {
						enabled: false
					},
					interaction: {
						intersect: false,
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							font: {
								size: 14
							}
						}
					},
					title: {
						position: 'bottom',
						display: true,
						text: 'Image Comparison Count',
						font: {
							size: 18
						}
					},
				},
				onClick: (event, activeEls) => {
					const datasetIndex = activeEls[0]?.datasetIndex
					const datasetLabel = event.chart.data.datasets[datasetIndex]?.label || null
					if (!datasetLabel) return					
					this.selectedType = datasetLabel
					this.offset = 0
					this.current = 1
					this.fetch()
				},
			}
		},
	},
	methods: {
		async fetch() {
			this.isMounted = true

			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
        address: this.address,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
				type: this.selectedType,
			};
			try {
				this.isLoading = true
				const result = await this.$api.nestle.getImageAccuracy(params)
				renameYandexIA(result)
				this.response = result
			} catch (error) {
				console.error("Data aren't found")
			} finally {
				this.isLoading = false
			}
		},
		changeFilter(item) {
			let index = this.filterOptions.findIndex((el) => el.label === item.label)
			let newItem = { ...item, value: !item.value}
			this.$set(this.filterOptions, index, newItem)
		},
		clearFilter() {
			console.log('clear filter')
		},
		applyFilter() {
			console.log('apply filter')
		}
	},
	watch: {
		needUpdateResult: {
			async handler() {
				console.log('NeedUpateResult')
				this.offset = 0
				this.current = 1
				await this.fetch()
			}
		},
		current: {
			async handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},
		amountOnPage: {
			async handler() {
				this.limit = this.amountOnPage
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.accuracy {
	&__nav {
		width: 190px;
	}
	&__date {
		width: 100px;
		color: color(white);
		cursor: pointer;
	}
	&__filter {

	}
	&__section {
		padding: 20px;
		background-color: color(white);
		border-radius: 4px;
	}
	&__chart {
    ::v-deep .chart {
			height: 340px;
		}
	}
	&__product {
		width: 32%;
		&:nth-child(3n+2) {
			margin-right: 24px;
			margin-left: 24px;
		}
	}
}
.header {
	margin-bottom: 32px;

	&-right {
		margin-left: auto;
	}
}
.filter {
	&__label {
		margin-left: 10px;
		padding: 0 4px 0 10px;
		outline: 1px color(gray-400) solid;
		border-radius: 3px;
		color: color(gray-700);
		background-color: color(white);
		cursor: pointer;

		&-icon {
			margin-left: 4px;
		}
	}

	&__bottom {
		margin-top: 15px;
		padding-top: 5px;
		border-top: 1px solid color(gray-300);
	}
	&__clear {
		color: color(blue-bright);
		cursor: pointer;
	}
	&__apply {
		padding: 4px 10px 5px 10px;
		background-color: color(blue-bright);
		color: color(white);
		cursor: pointer;
		border-radius: 4px;
	}
}
select{
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus{
		outline: none;
	}
}
.cards {
	width: 100%;
}
.empty-filter {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
</style>
